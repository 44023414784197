// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chatroom-js": () => import("./../../../src/pages/chatroom.js" /* webpackChunkName: "component---src-pages-chatroom-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-example-improvs-js": () => import("./../../../src/pages/example-improvs.js" /* webpackChunkName: "component---src-pages-example-improvs-js" */),
  "component---src-pages-improv-tips-js": () => import("./../../../src/pages/improv-tips.js" /* webpackChunkName: "component---src-pages-improv-tips-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-militaryrestaurant-js": () => import("./../../../src/pages/militaryrestaurant.js" /* webpackChunkName: "component---src-pages-militaryrestaurant-js" */),
  "component---src-pages-private-rooms-js": () => import("./../../../src/pages/private-rooms.js" /* webpackChunkName: "component---src-pages-private-rooms-js" */),
  "component---src-pages-single-player-js": () => import("./../../../src/pages/single-player.js" /* webpackChunkName: "component---src-pages-single-player-js" */)
}

