import React from 'react'
import './ExampleImprovsPage.css'
import SampleChatbox from './chatbox/SampleChatbox'

const example1 = {
  title: 'The Military Restaurant',
  you: 'Blue Ogre',
  peer: 'Yogi Okukura',
  conversation: [
    ['you', 'Blue Ogre', 'oh my. i am so hungry'],
    ['you', 'Blue Ogre', 'i really need something to eat'],
    ['peer', 'Yogi Okukura', "Don't look at me, meathead. I'm no meal."],
    ['you', 'Blue Ogre', 'then what on earth are you doing here behind the counter'],
    ['you', 'Blue Ogre', "of Merg's meat diner"],
    ['you', 'Blue Ogre', 'you are literally behind the counter'],
    ['peer', 'Yogi Okukura', 'Some obnoxious snob took me here.'],
    ['peer', 'Yogi Okukura', 'Also, you should go and get a salad.'],
    ['peer', 'Yogi Okukura', "you don't NEED anymore meat!"],
    ['you', 'Blue Ogre', 'ehhh?'],
    ['you', 'Blue Ogre', 'NO ONE TALKS TO ME LIKE THAT!!!!!'],
    ['you', 'Blue Ogre', 'I demand service!!!!'],
    ['you', 'Blue Ogre', "I don't believe it. I've been coming here for 3 years"],
    ['you', 'Blue Ogre', 'this is my first time meeting a jerk like you'],
    ['peer', 'Yogi Okukura', "Well I'm not just a jerk"],
    ['peer', 'Yogi Okukura', "I'm Yogi Okukura, the Ultimate Soldier!!"],
    ['you', 'Blue Ogre', 'oh my gosh!'],
    ['you', 'Blue Ogre', 'you must be here to buy the restaurant!!!'],
    ['you', 'Blue Ogre', "you're that military guy who wants to make this a military lunchroom"],
    ['you', 'Blue Ogre', 'where everyone must do 20 pushups just to order a salad'],
    ['peer', 'Yogi Okukura', "Yeah, tubby, I survived a killing game and I'm in a foul mood."],
    ['you', 'Blue Ogre', 'tubby?!!?!?!!!'],
    ['you', 'Blue Ogre', "i'll let it pass..."],
    ['you', 'Blue Ogre', 'but next time...'],
    ['peer', 'Yogi Okukura', "You don't scare me, bub."],
    ['you', 'Blue Ogre', 'RARARARAR'],
    ['you', 'Blue Ogre', '*flips table*'],
    ['you', 'Blue Ogre', 'GIVE ME A BURGER!!!'],
    ['peer', 'Yogi Okukura', "No way, fatso. Eat any more junk food and you'll get diabetes!"],
    ['you', 'Blue Ogre', 'EHHHH?????'],
    ['you', 'Blue Ogre', 'RARARARARA'],
    ['you', 'Blue Ogre', '*Pounds counter*'],
    ['you', 'Blue Ogre', 'A BURGER. NOW!!!'],
    ['peer', 'Yogi Okukura', 'YOU WANNA EAT? GET TO EXERCISING!'],
    ['you', 'Blue Ogre', 'FINE!!!'],
    ['you', 'Blue Ogre', 'THEN TELL ME HOW TO EXERCISE!!!'],
    ['peer', 'Yogi Okukura', 'START YOUR PUSH-UPS!'],
    ['peer', 'Yogi Okukura', 'GET GOING, YOU BIG OAF!'],
    ['peer', 'Yogi Okukura', "C'MON, I DON'T GOT ALL DAY!!"],
    ['you', 'Blue Ogre', 'EHHHHH?!!!'],
    ['you', 'Blue Ogre', 'fine!!'],
    ['you', 'Blue Ogre', '1'],
    ['you', 'Blue Ogre', '2'],
    ['you', 'Blue Ogre', '3'],
    ['you', 'Blue Ogre', 'gosh darn this is so toughhhh'],
    ['you', 'Blue Ogre', "i'm out of breath....."],
    ['peer', 'Yogi Okukura', "Aaaand that's why you don't eat too much."],
    ['you', 'Blue Ogre', 'i need a burger'],
    ['you', 'Blue Ogre', 'to replenish my strength'],
    ['peer', 'Yogi Okukura', "Choke down a veggie burger, then. It'll help."],
    ['you', 'Blue Ogre', 'i need a coke too!!!'],
    ['you', 'Blue Ogre', "WHERE'S MY BURGER!!!"],
    ['you', 'Blue Ogre', '*RARARARARAR* BURGER NOW!!!'],
    ['peer', 'Yogi Okukura', "*tosses the tray next to him* HERE'S YOUR VEGGIE BURGER AND..."],
    ['peer', 'Yogi Okukura', 'You gotta chug down some water!'],
    ['peer', 'Yogi Okukura', 'No beef or carbon in MY joint.'],
    ['peer', 'Yogi Okukura', 'If you wanna stuff your face with greasy food and get heart disease...'],
    ['peer', 'Yogi Okukura', '...THEN YOU BETTER GET OUT OF MY DINER!'],
    ['you', 'Blue Ogre', "fine! I'll eat the veggie burger"],
    ['you', 'Blue Ogre', '*takes a bite*'],
    ['you', 'Blue Ogre', 'Oohhhhh i like it'],
    ['you', 'Blue Ogre', 'GIMME ANOTHER!'],
    ['peer', 'Yogi Okukura', 'Good choice. Catch!'],
    ['you', 'Blue Ogre', 'yummm!'],
    ['you', 'Blue Ogre', "oooh i'm feeling the strength!"],
    ['you', 'Blue Ogre', '*does 5 pushups*'],
    ['you', 'Blue Ogre', 'BOO YAH!!!'],
    ['peer', 'Yogi Okukura', 'eat another veggie burger'],
    ['peer', 'Yogi Okukura', 'and take out your wallet'],
    ['peer', 'Yogi Okukura', 'its payment time'],
    ['you', 'Blue Ogre', 'HAHAHA'],
    ['you', 'Blue Ogre', "you think I'm paying for these fake burgers???"],
    ['you', 'Blue Ogre', 'oooooh thats funny!!!!'],
    ['you', 'Blue Ogre', 'ROFL!'],
    ['peer', 'Yogi Okukura', '*makes fists*'],
    ['peer', 'Yogi Okukura', "What'd you say, punk?"],
    ['you', 'Blue Ogre', 'uh...put me down for credit'],
  ],
}

const paddingtonExample = {
  title: 'Paddington and The Lost Temple',
  you: 'adventurer',
  peer: 'Paddington',
  conversation: [
    ['peer', 'Paddington', "I'm a bear, I like honey"],
    ['you', 'adventurer', 'i know. you took us on this adventure to find the Lost Honey of gold'],
    ['peer', 'Paddington', "Oh I did! I'm an old bear, I forgot"],
    ['you', 'adventurer', 'AHHH'],
    ['you', 'adventurer', "you're the guy leading this adventure"],
    ['you', 'adventurer', "we're in a huge lost temple in the middle of the amazon"],
    ['you', 'adventurer', "its your map we're following"],
    ['peer', 'Paddington', "Wait, let me get my compass, I'm sure I left it somewhere....."],
    ['peer', 'Paddington', "😨I can't find it..."],
    ['you', 'adventurer', 'im taking out my flashlight'],
    ['you', 'adventurer', 'this place is so dark'],
    ['peer', 'Paddington', "Oh yes that'll help, can't see much in here"],
    ['peer', 'Paddington', 'Feels damp'],
    ['you', 'adventurer', 'yeah, we better find this Lost Honey of gold soon'],
    ['you', 'adventurer', 'the roof looks like its gonna cave'],
    ['peer', 'Paddington', 'The last thing I remember was falling into a puddle and hearing something'],
    ['peer', 'Paddington', 'i dropped everything and ran'],
    ['peer', 'Paddington', 'but it was dark 😭'],
    ['you', 'adventurer', 'ooooooh, theres a puddle up ahead'],
    ['peer', 'Paddington', 'oh yah?!'],
    ['peer', 'Paddington', '...we better be careful'],
    ['peer', 'Paddington', "you'll lead I'll follow"],
    ['you', 'adventurer', 'OH MY GOSH!'],
    ['you', 'adventurer', 'the floor ahead of is sagging'],
    ['you', 'adventurer', 'it looks unstable'],
    ['peer', 'Paddington', "oh no. i'm a little heavy... *rubs my tummy*"],
    ['peer', 'Paddington', 'you should go.'],
    ['peer', 'Paddington', '😁'],
    ['you', 'adventurer', "uh okay, i'll take the lead"],
    ['you', 'adventurer', 'im tip toing across'],
    ['peer', 'Paddington', 'see anything? hows the floor?'],
    ['you', 'adventurer', 'its very shaky'],
    ['you', 'adventurer', 'my knees are shaking'],
    ['peer', 'Paddington', 'oh no. i hear something behind me.......'],
    ['peer', 'Paddington', "i think i'll try following you..........................."],
    ['you', 'adventurer', "i'm shining my flashlight behind you"],
    ['you', 'adventurer', 'uh oh.'],
    ['peer', 'Paddington', '🙀'],
    ['you', 'adventurer', 'is that a tiger?!'],
    ['peer', 'Paddington', "don't tell me"],
    ['peer', 'Paddington', "i'm not looking"],
    ['you', 'adventurer', 'Its growling at us!'],
    ['peer', 'Paddington', 'oh my gosh , should I run?!'],
    ['peer', 'Paddington', "I'm running"],
    ['peer', 'Paddington', "i don't care"],
    ['you', 'adventurer', 'quick, run!! deeper into the temple'],
    ['peer', 'Paddington', 'if the floor falls'],
    ['you', 'adventurer', 'follow me'],
    ['peer', 'Paddington', 'okokok'],
    ['you', 'adventurer', 'we got past the sinking floor'],
    ['peer', 'Paddington', '🏃🏼‍♂️'],
    ['you', 'adventurer', "There's an open door ahead of us!"],
    ['you', 'adventurer', 'lets go inside'],
    ['peer', 'Paddington', 'wait, gimme a sec'],
    ['peer', 'Paddington', 'i gotta catch my breath'],
    ['you', 'adventurer', 'oh gosh, the tigers following us!!'],
    ['you', 'adventurer', 'catch your breath latter paddy'],
    ['peer', 'Paddington', '😐fine.'],
    ['peer', 'Paddington', '🏃🏼‍♂️🏃🏼‍♂️🏃🏼‍♂️🏃🏼‍♂️🏃🏼‍♂️🏃🏼‍♂️🏃🏼‍♂️🏃🏼‍♂️🏃🏼‍♂️🏃🏼‍♂️🏃🏼‍'],
    ['you', 'adventurer', 'for a bear who eats lots of honey, you sure are slow'],
    ['peer', 'Paddington', "that's why i'm slow"],
    ['peer', 'Paddington', '🍯'],
    ['peer', 'Paddington', 'so good'],
    ['you', 'adventurer', 'i just slammed the door shut'],
    ['you', 'adventurer', 'we should be safe'],
    ['peer', 'Paddington', '*collapses on the floor*'],
    ['you', 'adventurer', 'oh no!'],
    ['peer', 'Paddington', 'my stomach heaving'],
    ['you', 'adventurer', 'Paddington!'],
    ['peer', 'Paddington', "i'll be ok"],
    ['you', 'adventurer', "now's not the time to be sick"],
    ['you', 'adventurer', 'get up'],
    ['peer', 'Paddington', "I'M NOT SICK. I'M OUT OF A SHAPE"],
    ['peer', 'Paddington', 'okok'],
    ['peer', 'Paddington', 'i slowly get up *knees crackle*'],
    ['you', 'adventurer', 'ahhhh'],
    ['you', 'adventurer', 'we are stuck in a lost temple in the middle of the amazon!'],
    ['peer', 'Paddington', 'ohh turn of your flashlight!'],
    ['peer', 'Paddington', 'you see that?!!!'],
    ['you', 'adventurer', 'im turning it on'],
    ['peer', 'Paddington', 'on the ceiling?'],
    ['you', 'adventurer', 'oh gosh!'],
    ['you', 'adventurer', 'the ceiling is sagging!'],
    ['you', 'adventurer', 'it looks like its gonna collapse any momeny!'],
    ['peer', 'Paddington', 'omg.should we open the door up again? the tigers there?!'],
    ['peer', 'Paddington', 'see any exits?!'],
    ['you', 'adventurer', "There's a window over there!"],
    ['peer', 'Paddington', 'it better be big'],
    ['you', 'adventurer', 'it looks just large enough for you'],
    ['you', 'adventurer', 'its gonna be tight though'],
    ['peer', 'Paddington', '*sucks in tummy*'],
    ['peer', 'Paddington', 'i got this'],
    ['you', 'adventurer', 'grab the rope from my backpack'],
    ['peer', 'Paddington', 'okok *rummages through your pack*'],
    ['peer', 'Paddington', 'found it!'],
    ['peer', 'Paddington', 'now what'],
    ['you', 'adventurer', "Good. we'll tie it to the huge stone next to the window"],
    ['you', 'adventurer', 'Now, lets climb out'],
    ['you', 'adventurer', "i'll go first"],
    ['peer', 'Paddington', 'fine, only coz your lighter'],
    ['you', 'adventurer', 'ok, im outside the window'],
    ['peer', 'Paddington', 'hurryyyy, ceiling looks sketchy'],
    ['you', 'adventurer', 'im climbing down the rope'],
    ['you', 'adventurer', 'i reached solid ground'],
    ['you', 'adventurer', 'you can climb after me'],
    ['peer', 'Paddington', 'can you see the bottom? oh good, my turn'],
    ['peer', 'Paddington', "*climbs through* ....i'm stuck"],
    ['peer', 'Paddington', 'omg.'],
    ['you', 'adventurer', 'suck in your tummy!!!'],
    ['peer', 'Paddington', "I'M SUCKING IT IN"],
    ['you', 'adventurer', "i'll climb back up and pull you!"],
    ['peer', 'Paddington', 'OH NO THE DOOR IS GETTING BASHED'],
    ['peer', 'Paddington', "HE KNOWS WE'RE TRYING TO GET OUT"],
    ['you', 'adventurer', "i'm pulling you!!!!!!!"],
    ['you', 'adventurer', "i've got olive oil from my backpack!!!"],
    ['you', 'adventurer', "i'm rubbing the window"],
    ['you', 'adventurer', 'its greasy now'],
    ['peer', 'Paddington', 'yes yes yes'],
    ['you', 'adventurer', 'COME ON!'],
    ['peer', 'Paddington', 'my tummys through!'],
    ['peer', 'Paddington', 'okokokokokokok'],
    ['you', 'adventurer', 'im sliding down the rope now'],
    ['peer', 'Paddington', '🧗🏻‍♀️'],
  ],
}

const example2 = {
  title: "The Hypnotist's Watch",
  you: 'Hypnotist master',
  peer: 'Mighty lawyer',
  conversation: [
    ['peer', 'Mighty lawyer', 'nice watch you got'],
    ['you', 'Hypnotist master', 'thanks. its pure gold'],
    ['you', 'Hypnotist master', 'a gift from a client'],
    ['peer', 'Mighty lawyer', 'wow! it looks so expensive'],
    ['peer', 'Mighty lawyer', 'thats quite the gift'],
    ['you', 'Hypnotist master', 'yes. the client was quite willing to part with it'],
    ['peer', 'Mighty lawyer', "oh wow. why's that?"],
    ['you', 'Hypnotist master', 'let me show youuuuuu'],
    ['you', 'Hypnotist master', 'look into my eyesssss'],
    ['peer', 'Mighty lawyer', 'oooh such beautiful eyes you have'],
    ['you', 'Hypnotist master', 'you are getting sleeepppyyyy'],
    ['you', 'Hypnotist master', 'so sleeeeepy'],
    ['peer', 'Mighty lawyer', 'ohhhhhhh'],
    ['peer', 'Mighty lawyer', 'so tireddddd'],
    ['you', 'Hypnotist master', 'keep looking into my eyes'],
    ['you', 'Hypnotist master', 'now repeat after me'],
    ['you', 'Hypnotist master', '"i will give my gold watches to the hypnotist master"'],
    ['peer', 'Mighty lawyer', '"i will give my gold watches to the hypnotist master..."'],
    ['peer', 'Mighty lawyer', 'but only if he gives me PIZZA!!!!'],
    ['you', 'Hypnotist master', 'what, why pizza?'],
    ['peer', 'Mighty lawyer', 'because I LOVE pizza!!'],
    ['you', 'Hypnotist master', 'I love pizza too!'],
    ['you', 'Hypnotist master', 'especially extra cheese pizza'],
    ['peer', 'Mighty lawyer', 'me too!'],
    ['peer', 'Mighty lawyer', 'anyone who likes extra cheesy pizza is a friend in my book'],
    ['you', 'Hypnotist master', 'gee thanks'],
    ['peer', 'Mighty lawyer', "since we're friends, can i have your gold watch"],
    ['you', 'Hypnotist master', 'sure thing.'],
    ['you', 'Hypnotist master', "here's the gold watch"],
    ['peer', 'Mighty lawyer', 'thanks. now look at my watch'],
    ['peer', 'Mighty lawyer', 'see it swinging back and forth'],
    ['peer', 'Mighty lawyer', 'back and forth'],
    ['you', 'Hypnotist master', "i'm looking"],
    ['peer', 'Mighty lawyer', 'you are getting sleepppppyyy'],
    ['peer', 'Mighty lawyer', 'keep looking at gold watch'],
    ['peer', 'Mighty lawyer', 'now repeat after me'],
    ['peer', 'Mighty lawyer', '"I will buy 5 pies of extra cheese pizza for the MIGHTY LAWYER!!"'],
    ['you', 'Hypnotist master', '"I will buy 5 pies of extra cheese pizza for the MIGHTY LAWYER!!"'],
    ['peer', 'Mighty lawyer', 'gee thanks'],
    ['you', 'Hypnotist master', 'my pleasure.'],
    ['you', 'Hypnotist master', 'but how do i pay for the pizza?'],
    ['peer', 'Mighty lawyer', 'here take this gold watch'],
    ['peer', 'Mighty lawyer', 'barter it as payment'],
    ['peer', 'Mighty lawyer', 'thanks for getting the pizza!!!!'],
    ['peer', 'Mighty lawyer', 'and you can eat it with me'],
    ['peer', 'Mighty lawyer', 'friend'],
    ['you', 'Hypnotist master', 'thanks friend'],
  ],
}

const example3 = {
  title: "The Swordfighter's Secret",
  you: 'News reporter',
  peer: 'Partying swordsman',
  conversation: [
    ['you', 'News reporter', 'welcome to the newsroom'],
    ['peer', 'Partying swordsman', 'Gee whiz. So many cameras'],
    ['you', 'News reporter', "That's right! this is channel 5"],
    ['you', 'News reporter', 'thanks for coming to be interviewed'],
    ['peer', 'Partying swordsman', "You're welcome"],
    ['peer', 'Partying swordsman', "ask me whatever you'd like"],
    ['you', 'News reporter', 'all rightw'],
    ['you', 'News reporter', 'how do you balance partying with your swordfighting lessons'],
    ['peer', 'Partying swordsman', 'good question.'],
    ['peer', 'Partying swordsman', 'I do them both at same time'],
    ['you', 'News reporter', 'how?'],
    ['peer', 'Partying swordsman', 'I blast music'],
    ['peer', 'Partying swordsman', 'and dance'],
    ['peer', 'Partying swordsman', 'while holding and waving my swords'],
    ['you', 'News reporter', 'amazing! tell me more'],
    ['peer', 'Partying swordsman', 'I also sing'],
    ['peer', 'Partying swordsman', 'at the top of my lungs'],
    ['peer', 'Partying swordsman', 'and i swordfight to the rhythm'],
    ['peer', 'Partying swordsman', 'of my voice'],
    ['you', 'News reporter', 'your voice?'],
    ['peer', 'Partying swordsman', 'yes. I sing a tune'],
    ['peer', 'Partying swordsman', 'and swordfight to the song i sing'],
    ['you', 'News reporter', 'can you give an example'],
    ['peer', 'Partying swordsman', 'sure thing'],
    ['peer', 'Partying swordsman', 'i might sing, "lalalala its timeeee to attack"'],
    ['peer', 'Partying swordsman', "and then I'll thrust my sword forward"],
    ['you', 'News reporter', 'incredible'],
    ['peer', 'Partying swordsman', 'exactly. i fight with beauty'],
    ['peer', 'Partying swordsman', 'thats my secret'],
    ['you', 'News reporter', 'Wow. That wraps up our interview'],
    ['you', 'News reporter', 'Thanks for sharing your singing swordfighting techniques'],
  ],
}

const ExampleImprovsPage = () => {
  return (
    <>
      <section className='white'>
        <h1 className='page-title'>Example Improvs</h1>

        <div className='flex-center examples-padding-bottom'>
          <SampleChatbox chat={example1} />
        </div>

        <div className='flex-center examples-padding-bottom'>
          <SampleChatbox chat={paddingtonExample} />
        </div>

        <div className='flex-center examples-padding-bottom'>
          <SampleChatbox chat={example2} />
        </div>

        <div className='flex-center examples-padding-bottom'>
          <SampleChatbox chat={example3} />
        </div>
      </section>
    </>
  )
}

export default ExampleImprovsPage
